import { getCurrencySymbol } from '@angular/common';

import {
  IS_METRICS_LABELS,
  IS_METRICS_UNITS,
  ISMetricEnum,
  ISMetricUnitEnum,
  ISNumericMetricKey,
} from '@ess/integrated-search/shared/utils';
import { ISTacticsEnum } from '@ess/integrated-search/tactics/shared/utils';
import { SharedFilters } from '@ess/shared/utils/models';

import { ISFiltersHelper } from './is-filters.helper';

export class ISNumericFiltersHelper {
  private static readonly __numericFilters: { [key in ISTacticsEnum]: ISNumericMetricKey[] } = {
    [ISTacticsEnum.CHANNEL_OVERLAP]: [
      ISMetricEnum.PPC_SPEND,
      ISMetricEnum.SEO_POSITION,
      ISMetricEnum.SEO_CLICKS,
      ISMetricEnum.SEO_CTR,
      ISMetricEnum.PPC_CLICKS,
      ISMetricEnum.PPC_CTR,
      ISMetricEnum.PPC_CONVERSIONS,
      ISMetricEnum.CONVERSIONS_VALUE,
      ISMetricEnum.COMBINED_IMPRESSIONS,
      ISMetricEnum.PAID_IMPRESSIONS_SHARE,
      ISMetricEnum.MAX_IMPRESSIONS,
      ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE,
      ISMetricEnum.SEO_IMPRESSIONS,
      ISMetricEnum.PPC_IMPRESSIONS,
      ISMetricEnum.PPC_CONVERSION_RATE,
      ISMetricEnum.PPC_ROAS,
      ISMetricEnum.PPC_CPC,
      ISMetricEnum.PPC_CPA,
    ],
    [ISTacticsEnum.SEARCH_GAPS]: [
      ISMetricEnum.COMBINED_IMPRESSIONS,
      ISMetricEnum.PAID_IMPRESSIONS_SHARE,
      ISMetricEnum.MAX_IMPRESSIONS,
      ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE,
      ISMetricEnum.MONTHLY_SEARCHES,
      ISMetricEnum.SEO_IMPRESSIONS,
      ISMetricEnum.PPC_IMPRESSIONS,
      ISMetricEnum.CONVERSIONS_VALUE,
      ISMetricEnum.SEO_POSITION,
      // TODO - add when available
      // ISMetricEnum.CLICKS_COVERAGE,
    ],
    [ISTacticsEnum.LANDING_PAGE_OPTIMISATION]: [
      ISMetricEnum.PPC_COST_SHARE,
      ISMetricEnum.PPC_CLICKS,
      ISMetricEnum.SEO_CLICKS,
      ISMetricEnum.PPC_CTR,
      ISMetricEnum.PPC_SPEND,
      ISMetricEnum.PPC_CONVERSIONS,
      ISMetricEnum.CONVERSIONS_VALUE,
      ISMetricEnum.COMBINED_IMPRESSIONS,
      ISMetricEnum.PAID_IMPRESSIONS_SHARE,
      ISMetricEnum.MAX_IMPRESSIONS,
      ISMetricEnum.MAX_PAID_IMPRESSIONS_SHARE,
      ISMetricEnum.SEO_IMPRESSIONS,
      ISMetricEnum.PPC_IMPRESSIONS,
      ISMetricEnum.PPC_ROAS,
      ISMetricEnum.PPC_CPC,
    ],
  };

  private static readonly __unlimitedPercentageRangeMetrics: ISNumericMetricKey[] = [
    ISMetricEnum.CLICKS_COVERAGE,
    ISMetricEnum.PPC_ROAS,
    ISMetricEnum.PPC_CONVERSION_RATE,
  ];

  static getNumericFiltersLabels(source: ISTacticsEnum): string[] {
    return this.__numericFilters[source].map((metric) => IS_METRICS_LABELS[metric]);
  }

  static getNumericFilters(source: ISTacticsEnum, currency: string | null | undefined): SharedFilters {
    return this.__numericFilters[source].reduce<SharedFilters>((filters: SharedFilters, metric: ISNumericMetricKey) => {
      const unit: ISMetricUnitEnum = IS_METRICS_UNITS[metric];
      switch (unit) {
        case ISMetricUnitEnum.CURRENCY:
          currency !== null &&
            currency !== undefined &&
            filters.push(ISFiltersHelper.getNumericMetricFilterOption(metric, getCurrencySymbol(currency, 'narrow')));
          break;
        case ISMetricUnitEnum.PERCENTAGE:
          filters.push(
            ISFiltersHelper.getNumericMetricFilterOption(
              metric,
              '%',
              this.__unlimitedPercentageRangeMetrics.includes(metric),
            ),
          );
          break;
        case ISMetricUnitEnum.INTEGER:
        default:
          filters.push(ISFiltersHelper.getNumericMetricFilterOption(metric));
      }
      return filters;
    }, []);
  }
}
