import { ISUniverseSearchTermSourcesHelper } from './is-search-terms-sources.helper';

import { ISSearchTermWithKeywords } from '../models';

export interface EntitySourceStats {
  manual: number;
  multiSource: number;
  other: number;
}

export class ISUniverseDeleteSearchTermsConfirmationTextHelper {
  static readonly getSingleEntityConfirmation = (entity: ISSearchTermWithKeywords): string => {
    const sources = ISUniverseSearchTermSourcesHelper.getSources(entity);
    if (!ISUniverseSearchTermSourcesHelper.isManuallyAdded(entity)) {
      return '';
    }

    return sources.length === 1
      ? // eslint-disable-next-line max-len
        `Because this search term does not occur neither in Google Ads nor in Google Search Console, it will no longer be visible neither in search term universe nor in tactics of this project.\n\nYou can manually add this search term again later, but currently available performance data and the recommendations for this search term will be lost.`
      : // eslint-disable-next-line max-len
        `Manually added source will be removed.\nAs this search term occurs in Google Ads and Google Search Console, it will be still visible in search term universe of this project and will be taken into consideration by the tactics if their conditions are met.`;
  };

  static readonly getMultipleEntitiesConfirmation = (multiple: boolean): string => {
    return `
    <div class="text-lg text-base-secondary">
    <p>"Manually added" source will be removed from ${multiple ? 'selected search terms' : 'this search term'}.</p>
    <br/>
    <p>Search terms that have never been added neither to Google Ads nor Google Search Control,
      will be removed from Integrated Search permanently. Their performance data and the recommendations will be lost.</p>
    <br/>
    <p>Search terms that have ever been added either to Google Ads or Google Search Control, will be still visible
      in Integrated search universe of this project and will be taken into consideration by the tactics (depending on selected period in “Time” filter).</p>
    </div>
    `;
  };

  static readonly getEntitySourcesStats = (entities: ISSearchTermWithKeywords[]): EntitySourceStats => {
    return entities.reduce(
      (stats, entity) => {
        const sources = ISUniverseSearchTermSourcesHelper.getSources(entity);
        const hasAddedManually = ISUniverseSearchTermSourcesHelper.isManuallyAdded(entity);

        return !hasAddedManually
          ? {
              ...stats,
              other: stats.other + 1,
            }
          : sources.length === 1
            ? { ...stats, manual: stats.manual + 1 }
            : {
                ...stats,
                multiSource: stats.multiSource + 1,
              };
      },
      {
        manual: 0,
        multiSource: 0,
        other: 0,
      },
    );
  };
}
