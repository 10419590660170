import { ISNumericMetricEnum } from '@ess/integrated-search/shared/utils';
import { SharedTimespanEnum } from '@ess/shared/utils/enums';
import { SharedTimespanHelper } from '@ess/shared/utils/helpers';

import {
  ISFilters,
  ISFiltersUpdate,
  ISTacticFilterRange,
  ISTacticsFiltersState,
  ISUniverseFilters,
  ISUniverseFiltersState,
} from '../models';

export class ISFiltersInitialStateHelper {
  static getInitialState(projectId: number, defaultCountry?: string): ISFilters {
    return {
      projectId,
      tactics: this.__getTacticsInitialState(defaultCountry),
      universe: this.__getUniverseInitialState(),
    };
  }

  static getOptionalEmptyValues(): ISFiltersUpdate {
    return {
      tactics: {
        channelOverlap: {
          recommendations: [],
          campaign_ids: [],
          ad_group_ids: [],
          assigned_category_value_ids: [],
          not_assigned_category_ids: [],
          search_term_difficulties: [],
          competitions: [],
          ...this.__initialRanges(),
        },
        searchGaps: {
          recommendations: [],
          assigned_category_value_ids: [],
          not_assigned_category_ids: [],
          search_term_difficulties: [],
          competitions: [],
          ...this.__initialRanges(),
        },
        landingPageOptimisation: {
          recommendations: [],
          urls: [],
          ppc_landing_page_quality_scores: [],
          ...this.__initialRanges(),
        },
      },
      universe: {
        searchTerms: {
          countries: [],
          sources: [],
          not_assigned_category_ids: [],
          assigned_category_value_ids: [],
          match_type: [],
          keyword_status: [],
          campaign_ids: [],
          ad_group_ids: [],
          timespan: undefined,
        },
        landingPages: {
          countries: [],
          sources: [],
          not_assigned_category_ids: [],
          assigned_category_value_ids: [],
          match_type: [],
          keyword_status: [],
          campaign_ids: [],
          ad_group_ids: [],
          timespan: undefined,
        },
      },
    };
  }

  private static __getTacticsInitialState(defaultCountry?: string): ISTacticsFiltersState {
    return {
      channelOverlap: {
        timespan: SharedTimespanHelper.getPresetTimespan(SharedTimespanEnum.DAYS_7),
        country: defaultCountry ?? 'GBR',
        recommendations: [],
        search_term__like: '',
        campaign_ids: [],
        ad_group_ids: [],
        assigned_category_value_ids: [],
        not_assigned_category_ids: [],
        ...this.__initialRanges(),
      },
      searchGaps: {
        timespan: SharedTimespanHelper.getPresetTimespan(SharedTimespanEnum.DAYS_30),
        country: defaultCountry ?? 'GBR',
        recommendations: [],
        search_term__like: '',
        assigned_category_value_ids: [],
        not_assigned_category_ids: [],
        search_term_difficulties: [],
        competitions: [],
        ...this.__initialRanges(),
      },
      landingPageOptimisation: {
        timespan: SharedTimespanHelper.getPresetTimespan(SharedTimespanEnum.DAYS_7),
        country: defaultCountry ?? 'GBR',
        recommendations: [],
        keyword__like: '',
        urls: [],
        ppc_landing_page_quality_scores: [],
        ...this.__initialRanges(),
      },
    };
  }

  private static __getUniverseInitialState(): ISUniverseFiltersState {
    const universeFilters: ISUniverseFilters = {
      countries: [],
      sources: [],
      not_assigned_category_ids: [],
      assigned_category_value_ids: [],
      match_type: [],
      keyword_status: [],
      includes: null,
      timespan: undefined,
      campaign_ids: [],
      ad_group_ids: [],
    };
    return {
      searchTerms: { ...universeFilters },
      landingPages: { ...universeFilters, timespan: SharedTimespanHelper.getPresetTimespan(SharedTimespanEnum.DAYS_7) },
    };
  }

  private static __initialRanges(): Required<ISTacticFilterRange> {
    return Object.values(ISNumericMetricEnum).reduce<ISTacticFilterRange>((state, metric) => {
      state[metric] = { from_: null, to: null };
      return state;
    }, {}) as Required<ISTacticFilterRange>;
  }
}
