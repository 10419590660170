import { IS_KEYWORD_STATUS_LABELS, ISAssignedCategory, ISKeywordStatusEnum } from '@ess/integrated-search/shared/utils';
import { essSortObjectsByProp } from '@ess/shared/utils/helpers';

import { ISSearchTermsRowsHelper } from './is-search-terms-rows.helper';

import { IS_ENTITY_SOURCE_LABELS } from '../consts';
import { ISFlatSearchTerm, ISSearchTermWithKeywords } from '../models';

export class ISSearchTermsExportHelper {
  static reduceSearchTermsToRowArray(
    selected: ISSearchTermWithKeywords[],
    headers: string[],
  ): (string | number | undefined)[][] {
    return essSortObjectsByProp(selected, 'search_term')
      .flatMap<ISFlatSearchTerm>((entity: ISSearchTermWithKeywords) =>
        ISSearchTermsRowsHelper.flattenSearchTerm(entity),
      )
      .reduce((acc: (string | number | undefined)[][], entity: ISFlatSearchTerm) => {
        const entityArray: (string | number | undefined)[] = [];
        // TODO refactor once data model is defined on backend
        headers.forEach((header) => {
          switch (header) {
            case 'Search Term':
              entityArray.push(entity.search_term);
              break;
            case 'Keyword':
              entityArray.push(entity.keyword);
              break;
            case 'Match Type':
              entityArray.push(entity.match_type);
              break;
            case 'Keyword status':
              entityArray.push(
                entity.is_active
                  ? IS_KEYWORD_STATUS_LABELS[ISKeywordStatusEnum.ACTIVE]
                  : entity.is_active === false
                    ? IS_KEYWORD_STATUS_LABELS[ISKeywordStatusEnum.PAUSED]
                    : '',
              );
              break;
            case 'Campaign':
              entityArray.push(entity.campaign?.campaign_name);
              break;
            case 'Campaign ID':
              entityArray.push(entity.campaign?.campaign_id);
              break;
            case 'Ad Group':
              entityArray.push(entity.ad_group?.ad_group_name);
              break;
            case 'Ad Group ID':
              entityArray.push(entity.ad_group?.ad_group_id);
              break;
            case 'Country':
              entityArray.push(entity.countries?.join(', ').toUpperCase());
              break;
            case 'Source':
              entityArray.push(entity.source.map((source) => IS_ENTITY_SOURCE_LABELS[source]).join(', '));
              break;
            default:
              entityArray.push(
                entity.categories.find(
                  (category: ISAssignedCategory) => category.title.toLowerCase() === header.toLowerCase(),
                )?.values[0].value || '',
              );
          }
        });

        acc.push(entityArray);

        return acc;
      }, []);
  }
}
