import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

import { ISUniverseSearchTermImportWorkbookHelper } from './is-universe-search-term-import-workbook-parser.helper';

type CategoryColumn = { title: string; index: number };

export class ISUniverseSearchTermImportValidatorHelper {
  private static __searchTermColumnNames = ['search term', 'search terms', 'search term name'];

  static fileFormatValidator(
    parseCategoriesToForm: (categoriesMap: Map<string, string[]>) => void,
    setEntitiesCount: (number: number) => void,
  ): AsyncValidatorFn {
    return async (control: AbstractControl<File | null>): Promise<ValidationErrors | null> =>
      Promise.resolve(control.value).then(async (value) => {
        if (!value) {
          return null;
        }

        const [headers, ...entities] = await ISUniverseSearchTermImportWorkbookHelper.parseWorkbookToArray(value);
        const errors: ValidationErrors = {};

        const entityColumns: string[] = [];
        const categoriesColumns: CategoryColumn[] = [];
        [...headers].forEach((title, index) => {
          const lowercaseTitle = title?.toLowerCase().trim();

          this.__searchTermColumnNames.includes(lowercaseTitle)
            ? entityColumns.push(lowercaseTitle)
            : categoriesColumns.push({ title: lowercaseTitle || '', index: +index });
        });

        this.__checkRequiredColumns([entityColumns], errors);
        this.__checkDuplicatedEntityColumn(entityColumns, errors);
        this.__checkCategoriesLength(categoriesColumns, errors);
        this.__checkCategoriesTitles(categoriesColumns, errors);
        const categoriesMap: Map<string, string[]> = this.__checkCategoriesValues(entities, categoriesColumns, errors);

        const valid = Object.keys(errors).length === 0;
        if (valid) {
          parseCategoriesToForm(categoriesMap);
          setEntitiesCount(entities.length);
        }

        return valid ? null : errors;
      });
  }

  private static __checkRequiredColumns(requiredColumns: string[][], errors: ValidationErrors): void {
    if (requiredColumns.some((subset) => subset.length < 1)) {
      errors['missingRequiredColumns'] =
        'Required column(s) is missing in uploaded file.\nSee example file or the rules below.';
    }
  }

  private static __checkDuplicatedEntityColumn(entityColumns: string[], errors: ValidationErrors): void {
    if (entityColumns.length > 1) {
      errors['duplicatedEntityColumn'] =
        `Search term column appears more than once with different names (${this.__searchTermColumnNames
          .map((n) => '"' + n + '"')
          .join(', ')}).\nWe support only 1 search term column.`;
    }
  }

  private static __checkCategoriesLength(categories: CategoryColumn[], errors: ValidationErrors): void {
    if (categories.length > 25) {
      errors['categoriesMaxLength'] = 'Too many category columns. We support up to 25 categories per project.';
    }
  }

  private static __checkCategoriesTitles(categories: CategoryColumn[], errors: ValidationErrors): void {
    let invalidTitle = false;
    let duplicated = false;
    const categoriesSet: Set<string> = new Set<string>();
    for (let i = 0; i < categories.length && !(invalidTitle && duplicated); i++) {
      invalidTitle = invalidTitle || categories[i].title.length < 2 || categories[i].title.length > 50;
      categoriesSet.add(categories[i].title);
      duplicated = duplicated || categoriesSet.size < i + 1;
    }

    if (invalidTitle) {
      errors['invalidCategoryTitle'] = 'Some category names do not fit within the range of 2-50 characters.';
    }
    if (duplicated) {
      errors['duplicatedCategory'] = 'Some category names are repeated. Change them or keep only one.';
    }
  }

  private static __checkCategoriesValues(
    entities: string[][],
    categories: CategoryColumn[],
    errors: ValidationErrors,
  ): Map<string, string[]> {
    const categoriesIndexMap: { [index: number]: string } = categories.reduce(
      (map, item) => ({ ...map, [item.index]: item.title }),
      {},
    );
    const valuesMap: { [index: number]: Set<string> } = categories.reduce(
      (map, item) => ({ ...map, [item.index]: new Set<string>() }),
      {},
    );
    let valueLengthError = false;
    let valuesListLengthError = false;

    entities.forEach((row) =>
      row.forEach((value, columnIndex) => {
        const category: string | null = categoriesIndexMap[columnIndex] ?? null;
        if (category !== null) {
          valuesMap[columnIndex].add(`${value}`.toLowerCase().trim());
          valueLengthError = valueLengthError || value.length > 60;
          valuesListLengthError = valuesMap[columnIndex].size > 50;
        }
      }),
    );

    if (valueLengthError) {
      errors['valueMaxLength'] = 'Names of some values do not fit within the range of 1 to 60 characters.';
    }

    if (valuesListLengthError) {
      errors['valuesListMaxLength'] =
        'Too many values in some categories. We support up to 50 values per one category.';
    }

    return new Map<string, string[]>(
      Object.keys(valuesMap).map((key) => [categoriesIndexMap[+key], Array.from(valuesMap[+key]).sort()]),
    );
  }
}
